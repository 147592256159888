<template>
  <v-footer app class="pl-2 pr-2 pb-0 pt-0" style="z-index: 5" color="transparent">
    <v-row justify="center">
<!--      <v-col cols="1">-->
<!--        <mex-btn color="primaryAccent" icon="mdi-arrow-left-circle" iconOnly large @click="$router.go(-1)" />-->
<!--      </v-col>-->
<!--      <v-spacer></v-spacer>-->
      <v-col cols="6">
        <v-carousel height="100%" hide-delimiter-background hide-delimiters progress
                    show-arrows-on-hover class="glassFooter">
          <template v-slot:prev="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon >
              <v-icon small>mdi-menu-left</v-icon>
            </v-btn>
          </template>
          <template v-slot:next="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon small>mdi-menu-right</v-icon>
            </v-btn>
          </template>
          <v-carousel-item v-for="(route, n) in quickRoutes" :key="`quick-route-${n}`" align-self="center">
            <v-row justify="center">
              <v-col v-for="(child, c) in route.children" :key="`quick-route-child-${c}`" cols="auto">
                <v-row>
                  <v-col class="mt-1 mb-1 pa-1" cols="auto">
                    <v-btn icon min-width="0" @click="goToQuickRoute(child)">
                      <v-icon v-if="child.icon" color="primaryAccent" small>{{ child.icon }}</v-icon>
                      <template v-else>
                        <mex-p :content="textToIcon(child.text)" class="ma-0" fontColor="primaryAccent" fontSize="body" fontWeight="bold" />
                      </template>
                    </v-btn>
                  </v-col>
                  <v-col v-if="c < route.children.length - 1" class="mt-1 mb-1" cols="auto">
                    <v-divider vertical />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="ma-0" justify="center">
              <v-col class="pa-0" cols="auto">
                <mex-p :content="route.group.toUpperCase()" class="ma-0 mb-1" fontColor="primaryAccent" fontSize="caption" />
              </v-col>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-col>
<!--      <v-spacer></v-spacer>-->
<!--      <v-col align-self="center" cols="1">-->
<!--        <v-flex v-if="currentLicenseServerVersion !== ''" class="d-flex justify-end">-->
<!--          <mex-p class="mb-0" :content="'v.' + this.currentLicenseServerVersion" fontColor="primaryAccent" fontSize="caption" />-->
<!--        </v-flex>-->
<!--      </v-col>-->
    </v-row>
  </v-footer>
</template>

<script>
import ServerService from "../../services/server.service";

export default {
  name: 'QuickLinkSliderFooter',
  props: {
    quickRoutes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      currentLicenseServerVersion: '',
    }
  },
  created() {
  },
  methods: {
    goToQuickRoute(route) {
      this.$router.push(route.to);
    },
    textToIcon(text) {
      let iconText = '';
      text.split(' ').forEach((val) => {
        iconText += val.substring(0, 1);
      });
      return iconText;
    },
  },
};
</script>

<style>
.glassFooter{
  background: rgba( 255, 255, 255, 0.1 );
  box-shadow: 0 8px 32px 0 rgba(36, 37, 63, 0.37)  !important;
  backdrop-filter: blur(30px)  !important;
  -webkit-backdrop-filter: blur( 4px ) !important;
  border-top-right-radius: 30px !important;
  border-top-left-radius: 30px !important;
  border: 1px solid rgba( 255, 255, 255, 0.18 ) !important;
  position: relative;
  bottom:-20px;
  transition: bottom ease 0.5s;
}
.glassFooter:hover {
  bottom: 2px;
}
</style>
