<template>
  <v-app id="inspire" class="viewIndex">
    <mex-app-bar
      :appBarMenuItems="menuItems"
      :appBarSpacer="false"
      :localSelectEnabled="false"
      appBarColor="primary"
      appBarMenuIcon="mdi-account"
      :appBarTitle="`MedITEX License Server v${currentLicenseServerVersion}`"
      @setDarkMode="setDarkMode(true)"
      @setLightMode="setDarkMode(false)"
    >
      <template v-slot:drawerToggleSlot>
        <!-- <v-icon @click="toggleDrawer">mdi-menu</v-icon> -->
          <img
            alt="drawerToggle"
            class="imageAnimation"
            src="../assets/Lizenzserver_Icon.png"
            width="20px"
            @click="toggleDrawer"
          />
      </template>
      <template v-slot>
        <v-row justify="end">
          <v-col align-self="center" cols="auto">
            <mex-p
              :content="'Session locks in ' + getRemainingTimeTillSessionLock"
              class="mb-0 mr-5"
              fontSize="caption"
            />
          </v-col>
        </v-row>
      </template>
    </mex-app-bar>

    <mex-nav-bar
      class="glassNavBar"
      :nav-bar-expand-on-hover="true"
      :drawerToggle="true"
      :navBarHeader="translatedNavBarHeader"
      :navBarItems="translatedListItems"
      navBarColor="transparent"
      @navbar-click="navBarClick"
    />
    <v-main>
      <v-container fluid>
        <!-- Provides the application the proper gutter -->
        <v-container class="fill-height" fluid>
          <!-- If using vue-router -->
          <v-fab-transition>
            <router-view></router-view>
          </v-fab-transition>
        </v-container>
      </v-container>
    </v-main>

    <quick-link-slider-footer :quickRoutes="translatedQuickLinks" />
    <v-overlay v-if="showReAuth" absolute opacity="1" style="z-index: 9998">
      <mex-sheet rounded>
        <login-2-f-a :session-unlock="true" @unlock-session="showReAuth = false" />
      </mex-sheet>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import { useIdle, useTimestamp } from "@vueuse/core";
import QuickLinkSliderFooter from "../components/LicSrvComponents/QuickLinkSliderFooter.vue";
import SystemUsersService from "../services/systemUsers.service";
import Login2FA from "../pages/Login2FA.vue";
import SystemSettingsService from "../services/systemSettings.service";
import requiredPermissions from "../requiredPermissions";
import ServerService from "../services/server.service";

export default {
  name: "ViewIndex",
  props: {
    source: String
  },
  components: {
    QuickLinkSliderFooter,
    Login2FA
  },
  setup() {
    const isIdle = useIdle(10 * 1000);

    return { isIdle };
  },
  computed: {
    ...mapGetters("sysAuth", ["isLoggedIn", "getUser", "getUserPermissions"]),
    ...mapGetters("theme", ["getDarkModeSetting", "getActTheme"]),
    getRemainingTimeTillSessionLock() {
      if (!this.showReAuth && this.sessionIdleLimit) {
        const now = useTimestamp();
        const timeTillSessionLock =
          this.sessionIdleLimit * 60 -
          Math.floor((now.value - this.isIdle.lastActive.value) / 1000);
        if (timeTillSessionLock > 0) {
          if (timeTillSessionLock < 2 * 60 && timeTillSessionLock > 60) {
            return `${Math.round(timeTillSessionLock / 60)} min`;
          }
          if (timeTillSessionLock > 60) {
            return `${Math.round(timeTillSessionLock / 60)} mins`;
          }
          if (timeTillSessionLock <= 60) {
            return `${timeTillSessionLock} s`;
          }
        }
        this.activateReAuth();
      }
      return 0;
    },
    getTheme() {
      return this.getDarkModeSetting ? "dark" : "light";
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    darkMode() {
      return this.$store.state.auth.user.darkMode;
    },
    translatedListItems() {
      const translatedItems = [];
      this.checkPermissions(translatedItems, this.navBarItems);
      return translatedItems;
    },
    translatedQuickLinks() {
      const translatedLinks = [];
      this.checkPermissions(translatedLinks, this.quickLinkSliderRoutes);
      return translatedLinks;
    },
    translatedNavBarHeader() {
      return {
        useHeader: false,
        title: this.$t("navbarHeader"),
        useIcon: true,
        icon: "mdi-head-snowflake-outline",
        useSubtitle: true,
        subtitle: this.$t("navbarSubHeader"),
        useDivider: true
      };
    }
  },
  data: () => ({
    drawer: false,
    showReAuth: false,
    sessionIdleLimit: null,
    currentLicenseServerVersion: "",
    permissions: [],
    navBarItems: [
      {
        title: "System User Dashboard",
        icon: "mdi-view-dashboard",
        to: "/views/system-user-dashboard",
        requiredPermissions: []
      },
      {
        divider: true,
        requiredPermissions: []
      },
      {
        group: "/views/system",
        title: "System",
        icon: "mdi-server",
        requiredPermissions: requiredPermissions.system,
        children: [
          {
            group: "/views/system/user-role-administration",
            title: "User Role Administration",
            requiredPermissions: requiredPermissions.userRoleAdministration,
            children: [
              {
                title: "Register User",
                icon: "mdi-account-plus",
                requiredPermissions: requiredPermissions.registerUser,
                to: "register-user"
              },
              {
                title: "User View",
                icon: "mdi-account-edit",
                requiredPermissions: requiredPermissions.userView,
                to: "user-view"
              },
              {
                title: "Users Overview",
                icon: "mdi-account-group",
                requiredPermissions: requiredPermissions.userOverview,
                to: "users-overview"
              },
              {
                title: "Add Role",
                icon: "mdi-briefcase-plus-outline",
                requiredPermissions: requiredPermissions.addRole,
                to: "add-role"
              },
              {
                title: "Role View",
                icon: "mdi-briefcase-edit-outline",
                requiredPermissions: requiredPermissions.roleView,
                to: "role-view"
              },
              {
                title: "Roles Overview",
                icon: "mdi-briefcase-outline",
                requiredPermissions: requiredPermissions.roleOverview,
                to: "roles-overview"
              }
            ]
          },
          {
            title: "System Settings",
            icon: "mdi-cog-box",
            requiredPermissions: requiredPermissions.systemSettings,
            to: "system-settings"
          },
          {
            title: "Failed Authentication Logs",
            icon: "mdi-boom-gate-alert",
            requiredPermissions: requiredPermissions.systemSettings,
            to: "failed-authentication-logs"
          },
          {
            title: "MAC Address Blocklist",
            icon: "mdi-shield-alert",
            requiredPermissions: requiredPermissions.systemSettings,
            to: "mac-address-blocklist"
          },
          {
            title: "Clinic UUID Blocklist",
            icon: "mdi-shield-alert-outline",
            requiredPermissions: requiredPermissions.systemSettings,
            to: "clinic-uuid-blocklist"
          }
        ]
      },
      {
        divider: true,
        requiredPermissions: []
      },
      {
        title: "Customer Administration",
        group: "/views/customer-administration",
        icon: "mdi-account-group-outline",
        requiredPermissions: requiredPermissions.customerAdministration,
        children: [
          {
            title: "Manual Data Upload",
            icon: "mdi-hospital-building",
            requiredPermissions: requiredPermissions.manuallyManagedClinics,
            to: "clinic-view-manually-managed"
          },
          {
            group: "/views/customer-administration/clinics",
            title: "Clinic Administration",
            requiredPermissions: requiredPermissions.clinicAdministration,
            children: [
              {
                title: "Clinics Overview",
                requiredPermissions: [],
                to: "clinics-overview"
              },
              {
                title: "Clinic View",
                icon: "mdi-hospital-building",
                requiredPermissions: [],
                to: "clinic-view"
              },
              {
                title: "Clinic Endpoints",
                requiredPermissions: [],
                to: "clinic-endpoints-view"
              },
            ]
          },
          {
            group: "/views/customer-administration/organizations",
            title: "Organization Administration",
            requiredPermissions: requiredPermissions.organizationAdministration,
            children: [
              {
                title: "Organization Overview",
                requiredPermissions: [],
                to: "organizations-overview"
              },
              {
                title: "Organization View",
                icon: "mdi-sitemap",
                requiredPermissions: [],
                to: "organization-view"
              }
            ]
          },
          {
            group: "/views/customer-administration/locations",
            title: "Location Administration",
            requiredPermissions: requiredPermissions.locationAdministration,
            children: [
              {
                title: "Location Overview",
                requiredPermissions: [],
                to: "locations-overview"
              },
              {
                title: "Location View",
                icon: "mdi-hospital-marker",
                requiredPermissions: [],
                to: "location-view"
              }
            ]
          }
        ]
      },
      {
        divider: true,
        requiredPermissions: []
      },
      {
        title: "License Administration",
        group: "/views/license-administration",
        icon: "mdi-certificate-outline",
        requiredPermissions: requiredPermissions.licenseAdministration,
        children: [
          {
            group: "/views/license-administration/rental",
            title: "Rental License",
            requiredPermissions: requiredPermissions.rentalLicense,
            children: [
              {
                to: "rental-license-settings",
                title: "Location License Settings",
                requiredPermissions: [],
                icon: "mdi-cog-box"
              },
              {
                to: "rental-license-view",
                title: "Rental License View",
                requiredPermissions: [],
                icon: "mdi-view-dashboard-outline"
              },
              {
                to: "rental-license-template-settings",
                title: "Template Settings",
                requiredPermissions: [],
                icon: "mdi-tune"
              },
            ]
          },
          {
            group: "/views/license-administration/feature",
            title: "Feature License",
            requiredPermissions: requiredPermissions.featureLicense,
            children: [
              {
                to: "feature-license-view",
                title: "Feature License View",
                requiredPermissions: [],
                icon: "mdi-puzzle"
              },
              {
                to: "feature-license-settings",
                title: "Feature License Settings",
                requiredPermissions: [],
                icon: "mdi-puzzle-check"
              }
            ]
          },
          {
            group: "/views/license-administration/settings",
            title: "License Settings",
            requiredPermissions: requiredPermissions.licenseSettings,
            children: []
          }
        ]
      },
      {
        title: "Statistics",
        group: "/views/statistics",
        icon: "mdi-chart-bell-curve-cumulative",
        requiredPermissions: requiredPermissions.statistics,
        children: [
          {
            group: "/views/statistics/cycle-types",
            title: "CYCLE TYPES",
            requiredPermissions: requiredPermissions.cycleTypesStatistics,
            children: [
              {
                to: "clinic-based-statistics",
                title: "CLINIC BASED STATISTICS",
                icon: "mdi-chart-line",
                requiredPermissions: []
              },
              {
                to: "yearly-cycle-group-statistics",
                title: "YEARLY CYCLE GROUP STATISTICS",
                icon: "mdi-chart-box-multiple",
                requiredPermissions: []
              }
            ]
          },
          {
            group: "/views/statistics/endpoints",
            title: "ENDPOINTS",
            requiredPermissions: requiredPermissions.endpointsStatistics,
            children: [
              {
                to: "clinic-based-statistics",
                title: "Clinic Statistics",
                requiredPermissions: []
              }
            ]
          },
          {
            to: "global-statistics",
            title: "GLOBAL STATISTICS",
            requiredPermissions: requiredPermissions.statistics
          }
        ]
      },
      {
        title: "SQL Broadcaster",
        group: "/views/sql-broadcaster",
        icon: "mdi-database-search",
        requiredPermissions: requiredPermissions.sqlBroadcaster,
        children: [
          {
            icon: "mdi-format-list-text",
            to: "sql-statements",
            title: "SQL Statements",
            requiredPermissions: []
          },
          {
            icon: "mdi-database-edit",
            to: "sql-statement-editor",
            title: "SQL Statement Editor",
            requiredPermissions: []
          },
          {
            icon: "mdi-database-export",
            to: "sql-statement-results",
            title: "SQL Statement Results",
            requiredPermissions: []
          }
        ]
      },
      {
        divider: true,
        requiredPermissions: []
      },
      {
        title: "Product Info",
        group: "/views/product-info",
        icon: "mdi-tag-text-outline",
        requiredPermissions: requiredPermissions.keyTEXAll,
        children: [
          {
            icon: "mdi-pill",
            to: "product-info-view",
            title: "Product Info View",
            requiredPermissions: []
          },
          {
            icon: "mdi-tag-multiple",
            to: "product-info-overview",
            title: "Product Info Overview",
            requiredPermissions: []
          },
        ]
      },
      {
        divider: true,
        requiredPermissions: []
      },
      {
        title: "keyTEX",
        icon: "mdi-key-chain-variant",
        to: "/views/keyTEX",
        // either permission for whole keytex or for individual keytex-keys (so far only skype, extend as needed in future) necessary
        requiredPermissions: requiredPermissions.keyTEXAny,
      },
      {
        divider: true,
        requiredPermissions: []
      },
      {
        title: "Release Notes",
        icon: "mdi-notebook",
        to: "/views/release-notes",
        requiredPermissions: requiredPermissions.keyTEXAll
      }
    ],
    menuItems: [
      {
        title: "Signed in as ",
        to: "/views/system-user-dashboard"
      },
      {
        title: "Logout",
        to: "/pages/logout"
      },
      {
        divider: true
      }
    ],
    darkLightSwitches: [
      {
        button: true,
        icon: "mdi-lightbulb-on",
        title: "Light",
        clickEvent: "setLightMode"
      },
      {
        button: true,
        icon: "mdi-lightbulb-outline",
        title: "Dark",
        clickEvent: "setDarkMode"
      }
    ],
    quickLinkSliderRoutes: [
      {
        group: "Clinic Administration",
        requiredPermissions: requiredPermissions.clinicAdministration,
        children: [
          {
            text: "Clinic Overview",
            to: "/views/customer-administration/clinics/clinics-overview",
            requiredPermissions: []
          },
          {
            icon: "mdi-hospital-building",
            to: "/views/customer-administration/clinics/clinic-view",
            requiredPermissions: []
          }
        ]
      },
      {
        group: "Organization Administration",
        requiredPermissions: requiredPermissions.organizationAdministration,
        children: [
          {
            text: "Organization Overview",
            to: "/views/customer-administration/organizations/organizations-overview",
            requiredPermissions: []
          },
          {
            icon: "mdi-sitemap",
            to: "/views/customer-administration/organizations/organization-view",
            requiredPermissions: []
          }
        ]
      },
      {
        group: "Location Administration",
        requiredPermissions: requiredPermissions.locationAdministration,
        children: [
          {
            text: "Location Overview",
            to: "/views/customer-administration/locations/locations-overview",
            requiredPermissions: []
          },
          {
            icon: "mdi-hospital-marker",
            to: "/views/customer-administration/locations/location-view",
            requiredPermissions: []
          }
        ]
      },
      {
        group: "System",
        requiredPermissions: requiredPermissions.userRoleAdministration,
        children: [
          {
            icon: "mdi-account-plus",
            requiredPermissions: requiredPermissions.registerUser,
            to: "/views/system/user-role-administration/register-user"
          },
          {
            icon: "mdi-account-edit",
            requiredPermissions: requiredPermissions.userView,
            to: "/views/system/user-role-administration/user-view"
          },
          {
            icon: "mdi-account-group",
            requiredPermissions: requiredPermissions.userOverview,
            to: "/views/system/user-role-administration/users-overview"
          },
          {
            icon: "mdi-briefcase-plus-outline",
            requiredPermissions: requiredPermissions.addRole,
            to: "/views/system/user-role-administration/add-role"
          },
          {
            icon: "mdi-briefcase-edit-outline",
            requiredPermissions: requiredPermissions.roleView,
            to: "/views/system/user-role-administration/role-view"
          },
          {
            icon: "mdi-briefcase-outline",
            requiredPermissions: requiredPermissions.roleOverview,
            to: "/views/system/user-role-administration/roles-overview"
          }
        ]
      },
    ],
    permaFetch: null
  }),
  created() {
    this.$vuetify.theme.themes = this.getActTheme;
    this.$vuetify.theme.dark = this.getDarkModeSetting;
    this.menuItems.push(this.darkLightSwitches[this.getDarkModeSetting ? 0 : 1]);

    SystemUsersService.getOwnSystemUserData(this.getUser).then(response => {
      this.menuItems[0].title += response.data.username;
    });

    SystemSettingsService.getSystemSettingIdleLimit().then(systemSettingsResponse => {
      this.sessionIdleLimit = systemSettingsResponse.data.SYS_sessionIdleLimit;
    });

    if (this.isLoggedIn) {
      this.$store.dispatch("badges/fetchNotifications", {
        systemUserID: this.getUser,
        toast: true
      });
      this.fetchNotifications();
    }

    ServerService.getCurrentLicenseServerVersion().then((response) => this.currentLicenseServerVersion = response.data.version);
  },
  beforeDestroy() {
    clearInterval(this.permaFetch);
  },
  methods: {
    checkPermissions(newArray, oldArray) {
      for (let i = 0; i < oldArray.length; i++) {
        if (
          oldArray[i].requiredPermissions.length === 0 ||
          requiredPermissions.checkRequiredPermissions(
            oldArray[i].requiredPermissions,
            this.getUserPermissions
          )
        ) {
          const object = { ...oldArray[i] };
          delete object.children;
          newArray.push(object);
          if (oldArray[i].children) {
            newArray[newArray.length - 1].children = [];
            this.checkPermissions(newArray[newArray.length - 1].children, oldArray[i].children);
          }
        }
      }
    },
    activateReAuth() {
      this.showReAuth = true;
      this.$store.commit("sysAuth/lockSession");
    },
    fetchNotifications() {
      this.permaFetch = setInterval(() => {
        if (this.isLoggedIn && !this.showReAuth) {
          this.$store.dispatch("badges/fetchNotifications", {
            systemUserID: this.getUser,
            toast: true
          });
        }
      }, 60000);
    },
    // inlineBgImage() {
    //   // eslint-disable-next-line global-require
    //   const bgImage = require('../assets/Lizenzserver_Backround_only.png');
    //   return (
    //     ` backgroundImage: url("${bgImage}"); ` +
    //     ` background-size: cover;` /* for IE9+, Safari 4.1+, Chrome 3.0+, Firefox 3.6+ */ +
    //     `-webkit-background-size: cover;` /* for Safari 3.0 - 4.0 , Chrome 1.0 - 3.0 */ +
    //     `-moz-background-size: cover;` /* optional for Firefox 3.6 */ +
    //     `-o-background-size: cover;` /* for Opera 9.5 */ +
    //     `margin: 0;` /* to remove the default white margin of body */ +
    //     `padding: 0;` /* to remove the default white margin of body */ +
    //     `overflow: hidden;`
    //   );
    // },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    setDarkMode(darkMode) {
      this.$store.commit("theme/switchDarkMode", darkMode);
      this.$router.go();
    },
    navBarClick(item) {
      if (item.theme) {
        this.$vuetify.theme.themes.light = item.theme.light;
        this.$vuetify.theme.themes.dark = item.theme.dark;
      }
    }
  }
};
</script>
<style scoped>
.viewIndex {
  background: var(--v-background-base);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.glassNavBar{
  background: rgba( 255, 255, 255, 0.9 );
  box-shadow: 0 8px 32px 0 rgba(36, 37, 63, 0.37);
  backdrop-filter: blur( 25px );
  -webkit-backdrop-filter: blur( 4px );
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border: 1px solid rgba( 255, 255, 255, 0.18 ) !important;
  border-left: 1px solid transparent !important;
  z-index: 10;
  margin-top: 20px;
  height: 100% !important;
}

</style>
